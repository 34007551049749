
import Slider from "react-slick";
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { SiteMedia } from "../../../../siteWidgets/siteMedia"
import { LinkTargetProperties } from "../../../../../global/components/linkSettings/interfaces"
import { LinkedItemVideoSettings } from "../../../../commonDataObjects/linkedItemsVideoSettings"
import { SiteMediaData } from "../../../../interfaces";
import { MediaContentTypes } from "constants/contentTypes"
import { SiteImageData } from "widgets/interfaces"
import { separateHourFromDateAndFormatToString, calculateShouldAddArticleAutoplayIndicator } from "widgets/widgetsUtils"
import { ContentSiteItemData } from "./generalCommercialTeaserComponenta";
import { SlotIconDisplay } from "../../../../commonComponents/inline/slotIconDisplay/slotIconDisplay";


interface GeneralCommercialTeaserMobileProps {
    tabLink: string
    tabLinkTarget: LinkTargetProperties
    tabBackgroundImage: string
    imageWidth: number
    imageHeight: number
    itemList: ContentSiteItemData[]
    fieldsDisplaySettings: any
    videoSettings: LinkedItemVideoSettings
    numberOfVisibleItems: number
    bottomLinkText: string
    tabTitleText: string
    tabSubTitleText: string
    commercialText: string
    gallerySeconds: number
    commericalTeaserType: string
    marginColor?: string
    titleFontSize?: string
    titleColor?: string
}
interface ArrowProps {
    onClick?: () => void;
}

export class GeneralCommercialTeaserMobile extends React.Component<GeneralCommercialTeaserMobileProps, { currentSlide: number }>{
    public static siteScriptName = "GeneralCommercialTeaserMobile"
    constructor(props) {
        super(props);
        this.state = {
            currentSlide: 0
        }
    }

    render() {
        const { tabLink, tabLinkTarget, tabBackgroundImage, imageWidth, imageHeight, itemList, fieldsDisplaySettings, videoSettings, numberOfVisibleItems,
            bottomLinkText, tabTitleText, tabSubTitleText, commercialText, commericalTeaserType, marginColor, titleColor, titleFontSize } = this.props
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "", ariaHidden: true } as SiteImageData
        const isMore = fieldsDisplaySettings.isAuthorVisible || fieldsDisplaySettings.isHourVisible || fieldsDisplaySettings.isDateVisible
        const isComma = fieldsDisplaySettings.isAuthorVisible && (fieldsDisplaySettings.isHourVisible || fieldsDisplaySettings.isDateVisible);
        const { currentSlide } = this.state;

        const NextArrow = ({ onClick }: ArrowProps): JSX.Element => (<button disabled={currentSlide == itemList.length - 1} onClick={onClick} className="arrows-pf LeftArrow-pf"></button>);
        const PrevArrow = ({ onClick }: ArrowProps): JSX.Element => (<button disabled={currentSlide == 0} onClick={onClick} className="arrows-pf RightArrow-pf"></button>);

        let settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            infinite: false,
            rtl: true,
            className: 'commercial-teaser-carusel',
            prevArrow: <NextArrow />,
            nextArrow: <PrevArrow />,
            afterChange: (index) => {
                this.setState({ currentSlide: itemList.length - 1 - index });
            }
        }

        return (
            <div className={`GeneralCommercialTeaserMobile-pf ${commericalTeaserType}`} >

                <div className="header">
                    <HyperLinkBuilder className="tabTitleLink"
                        title={tabTitleText}
                        href={tabLink}
                        linkTarget={tabLinkTarget}>
                        <span className="tabTitle" style={{ fontSize: `${titleFontSize}px`, color: marginColor }}>{tabTitleText}</span>
                    </HyperLinkBuilder>
                </div>

                {/* <div className="CommericalTeaserTab">
                    <HyperLinkBuilder className="tabTitleLink"
                        title={tabTitleText}
                        href={tabLink}
                        linkTarget={tabLinkTarget}>
                        <span className="tabTitle">{tabTitleText}</span>
                    </HyperLinkBuilder>
                    <span className="tabSubTitle">{tabSubTitleText}</span>
                </div> */}

                <div className="slotList" style={{ '--margin-color': marginColor }}>
                    <Slider {...settings}>
                        {itemList.map(item => {
                            const shouldAddArticleAutoplayIndicator = calculateShouldAddArticleAutoplayIndicator(item.icon, item.media);
                            return (
                                <article key={item.itemId} className="slotView">
                                    <div className="MediaCarousel">
                                        <SiteMedia
                                            allMedias={[]}
                                            data={item.media !== undefined ? item.media : emptyMedia}
                                            width={imageWidth}
                                            height={imageHeight}
                                            videoSettings={videoSettings}
                                            itemLinkUrl={item.titleLink}
                                            isImageLinkable={true}
                                            linkTarget={item.titleTargetLink}
                                            shouldAddArticleAutoplayIndicator={shouldAddArticleAutoplayIndicator}
                                        />
                                        <SlotIconDisplay itemId={item.itemId} icon={item.hasVideo ? "video" : "none"} iconClassName="large" isIconVisible={shouldAddArticleAutoplayIndicator} />
                                    </div>
                                    <div className="slotTitle">
                                        <HyperLinkBuilder
                                            href={item.titleLink}
                                            linkTarget={item.titleTargetLink}>
                                            <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                        </HyperLinkBuilder>
                                        {isMore && <div className="moreDetails">
                                            <span className="dateView">{separateHourFromDateAndFormatToString(item.date,
                                                { showHour: false, showDate: fieldsDisplaySettings.isDateVisible })} </span>
                                            {isComma && <span className="commaView"> , </span>}
                                            {fieldsDisplaySettings.isAuthorVisible && <span className="authorInfo" dangerouslySetInnerHTML={{ __html: item.author }} />}
                                        </div>}
                                    </div>
                                </article>
                            )
                        }
                        )}
                    </Slider>
                    <HyperLinkBuilder style={{ color: marginColor }} className="bottomTitleLink" href={tabLink} linkTarget={tabLinkTarget}>{bottomLinkText}</HyperLinkBuilder>
                </div>
            </div >
        )
    }
}





