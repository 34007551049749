import * as axios from "axios";
import { ArticleExtraData } from "articlePage/stateInterfaces";
import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces";
import { addAxiosDateTransformerSettings } from "../../../../../utils/network/axiosDateTransformer";
import { StoriesAutoSlotItem } from "./storiesAutoSlotItem";
import { ISlot } from "./storiesAutoWrapper";
import Slider from 'react-slick'
import { StoriesItem } from "./storiesItem";
import { disablePageRefresh, enablePageRefresh } from "../../../../siteWidgets/siteWidgetUtils";
import { Splide, SplideSlide } from '@splidejs/react-splide';

interface StoriesAutoComponentaProps {
    componentaId?: string
    fieldsDisplaySettings: IFieldDisplayData
    hideVideoAds?: boolean
    tabBackgroundColor?: string
    slots: ISlot[]
    storiesProgressTime: number
    tabTitle: string
}

interface StoriesAutoComponentaState {
    storyEntities: StoriesEntity[]
    pageNumber: number
    isStoriesDisplay: boolean
    activeEntityIndex: number
}

export interface StoriesEntity extends ISlot {
    linkedItems: ArticleExtraData[]
    slotId: string
    isAllStoriesFinished:boolean
}

export const DICT = {
    "The link has been copied to the clipboard":"تم نسخ الرابط إلى الحافظة"
}
export class StoriesAutoComponenta extends React.Component<StoriesAutoComponentaProps, StoriesAutoComponentaState> {
    public static siteScriptName = "StoriesAutoComponenta"
    constructor(props) {
        super(props)
        this.state = {
            storyEntities: [],
            pageNumber: 0,
            isStoriesDisplay: false,
            activeEntityIndex: 0,
        }
    }
    
    private splideSliderRef;

    componentDidMount() {
        this.initializeComponent();
        
    }

    private initializeComponent = async () => {
        await this.getArticles();
        this.handleFinishedStories();
    }

    private getArticles = async () => {
        const { componentaId, slots } = this.props;
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());

        try {
            const articleRequests = slots.map(async (item) => {
                const response = await axiosInstance.get(`/iphone/json/api/article_list/${componentaId}/type/${item.contentSource.type}/id/${item.contentSource.link.id}`);
                // @ts-ignore
                const linkedItems = response.data.data as ArticleExtraData[];
                return {
                    ...item,
                    linkedItems,
                    slotId: item.slotId,
                    isAllStoriesFinished: false,
                };
            });

            const allArticles: StoriesEntity[] = await Promise.all(articleRequests);

            this.setState({ storyEntities: allArticles });
        } catch (error) {
            console.error("Error getting headline data:", error);
        }
    }

    private onStoriesOpen = (clickedIndex: number) => {
        this.setState({ isStoriesDisplay: true, activeEntityIndex: clickedIndex });
        disablePageRefresh();
    }

    private onCloseVideoGalleryView = () => {
        this.setState({ isStoriesDisplay: false });
        enablePageRefresh();
    }

    private onStorySelect = (index: number) => {
        this.splideSliderRef.go(index);
        this.setState({ activeEntityIndex: index });
    }

    private onAllStoriesEnd = (nextSlideIndex: number) => {
        const { storyEntities } = this.state;
        if (nextSlideIndex > storyEntities.length - 1) return;
        this.splideSliderRef.go(nextSlideIndex);
        this.setState({ activeEntityIndex: nextSlideIndex });
    }

    private handleNextSlide = () => {
        this.splideSliderRef.go("1");
        this.setState({ activeEntityIndex: this.state.activeEntityIndex + 1 });
    }

    private handlePrevSlide = () => {
        this.splideSliderRef.go("-1");
        this.setState({ activeEntityIndex: this.state.activeEntityIndex - 1 });
    }

    private handleFinishedStories = () => {
        const { storyEntities } = this.state;
        const updatedStoryEntities = storyEntities.map((entity) => {
            const storyCount = localStorage.getItem(entity.slotId);
            const isAllStoriesFinished = +storyCount === entity.linkedItems.length - 1 || entity.isAllStoriesFinished;
            return {
                ...entity,
                isAllStoriesFinished,
            }
        })

        this.setState({ storyEntities: updatedStoryEntities });
    }

    public render() {
        const { fieldsDisplaySettings, tabTitle, storiesProgressTime } = this.props;
        const { storyEntities, isStoriesDisplay, activeEntityIndex } = this.state;

        const desktopSettings = {
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: false,
            rtl: true,
            initialSlide: -2,
        };

        const storiesOptions = {
            type: 'slide',
            speed: 800,
            perPage: 5,
            perMove: 1,
            focus: 'center',
            trimSpace: false,
            arrows: false,
            pagination: false,
            start: activeEntityIndex,
            gap: "1em",
            easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
            direction: "rtl",
        };

        return (
            <div className="storiesAutoComponenta">
                {fieldsDisplaySettings.isTabVisible &&
                    <div className="storiesAutoComponentaTab componentaTab">
                        <div className="tabTitle">{tabTitle}</div>
                    </div>
                }
                {isStoriesDisplay && <div className="closeBtn" onClick={this.onCloseVideoGalleryView}></div>}
                <div className="galleryWrapper">
                    <Slider {...desktopSettings} className="slider"  >
                        {storyEntities.map((entity, index) => <StoriesAutoSlotItem
                            key={entity.slotId}
                            entity={entity}
                            onStoriesOpen={this.onStoriesOpen}
                            index={index} 
                            isAllStoriesFinished={entity.isAllStoriesFinished}/>)}
                    </Slider>
                </div>
                {isStoriesDisplay &&
                    <div className="storiesDisplayWrapper">
                        <Splide
                            options={storiesOptions}
                            ref={ref => this.splideSliderRef = ref}
                        >
                            {storyEntities.map((entity, index) => (
                                <SplideSlide>
                                    <StoriesItem
                                        key={entity.slotId}
                                        entity={entity}
                                        index={index}
                                        activeEntityIndex={activeEntityIndex}
                                        storiesProgressTime={storiesProgressTime}
                                        onStorySelect={this.onStorySelect}
                                        handleNextSlide={this.handleNextSlide}
                                        handlePrevSlide={this.handlePrevSlide}
                                        onAllStoriesEnd={this.onAllStoriesEnd}
                                        numberOfEntities={storyEntities.length}
                                        handleFinishedStories={this.handleFinishedStories}
                                    />
                                </SplideSlide>
                            ))}
                        </Splide>
                    </div>
                }
            </div>
        )
    }
}