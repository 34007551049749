import { ReactInstaStories } from "../../../react-insta-stories/reactInstaStories";
import { Story } from "../../../react-insta-stories/interfaces";
import { StoriesEntity } from "./storiesAutoComponenta";
import { SiteMedia } from "../../../../siteWidgets/siteMedia";
import { FrontCopyButton } from "../../../../commonComponents/inline/frontCopyButton/frontCopyButton";
import { DICT } from "./storiesAutoMobileComponenta";
import * as classNames from "classnames"

interface StoriesItemProps {
    entity: StoriesEntity
    activeEntityIndex: number
    index: number
    storiesProgressTime: number
    onStoriesClose: () => void
    handleNextSlide: () => void
    handlePrevSlide: () => void
    handleFinishedStories: () => void
}

interface StoriesItemState {
    isPaused: boolean
    stories: Story[]
    currentIndex: number
    isMuted: boolean
    windowHeight: number
}

export class StoriesItemMobile extends React.Component<StoriesItemProps, StoriesItemState> {
    constructor(props) {
        super(props);
        this.state = {
            isPaused: true,
            stories: null,
            currentIndex: 0,
            isMuted: false,
            windowHeight: window.innerHeight,
        }
    }

    componentDidMount() {
        const { entity: { slotId } } = this.props;
        this.getStories();
        this.getStoryCurrentIndex(slotId);

    }

    componentDidUpdate(prevProps) {
        const { entity: { slotId }, activeEntityIndex } = this.props;
        if (prevProps.activeEntityIndex !== this.props.activeEntityIndex) {
            this.getStories();
        }
        if (prevProps.activeEntityIndex !== activeEntityIndex) this.getStoryCurrentIndex(slotId);
    }

    componentWillUnmount() {
        const { entity: { slotId }, handleFinishedStories } = this.props;
        this.getStoryCurrentIndex(slotId);
        handleFinishedStories();
    }

    public getStories() {
        const { entity, activeEntityIndex, index } = this.props;
        const storiesArray = entity.linkedItems.map((linkedItem) => {
            const isVideoStory = linkedItem.hasVideo;
            const url = isVideoStory && linkedItem.video ? linkedItem.video[0].high_res_link : linkedItem.promotionImageDetails.url;
            return { url, type: isVideoStory ? 'video' : "", muted: index === activeEntityIndex ? false : true, link: linkedItem.publishedLink }
        });
        this.setState({ stories: storiesArray });
    }

    public onToggleMute = () => {
        const videoElement = document.querySelector(".storiesDisplayWrapper .slick-active .storyContent video") as HTMLVideoElement;
        if (!videoElement) return;
        videoElement.muted ? this.setState({ isMuted: false }, () => videoElement.muted = false) : this.setState({ isMuted: true }, () => videoElement.muted = true);
    }

    public setStoryCurrentIndex = (slotId: string, index: number) => {
        localStorage.setItem(slotId, index.toString())
    }

    public getStoryCurrentIndex = (slotId: string) => {
        const currentIndex = localStorage.getItem(slotId);
        this.setState({ currentIndex: +currentIndex });
    }

    public onNextStory = () => {
        const { currentIndex, stories } = this.state;
        const { entity: { slotId }, handleNextSlide } = this.props;
        if (currentIndex === stories.length - 1) return handleNextSlide();
        console.log("story next")

        this.setState({ currentIndex: this.state.currentIndex + 1 }, () => this.setStoryCurrentIndex(slotId, this.state.currentIndex));
    }

    public onPrevStory = () => {
        const { currentIndex } = this.state;
        const { entity: { slotId }, handlePrevSlide } = this.props;
        if (currentIndex === 0) return handlePrevSlide();
        this.setState({ currentIndex: this.state.currentIndex - 1 }, () => this.setStoryCurrentIndex(slotId, this.state.currentIndex));
    }

    public onStoryEnd = () => {
        const { entity: { slotId } } = this.props;
        const { currentIndex, stories } = this.state;
        if (currentIndex === stories.length - 1) return;

        const localStorageCurrentIndex = localStorage.getItem(slotId);
        this.setStoryCurrentIndex(slotId, +localStorageCurrentIndex + 1);
    }

    public render() {
        const { onStoriesClose, entity: { promotionName, media, linkTarget, link }, handleNextSlide, index, activeEntityIndex, storiesProgressTime } = this.props;
        const { stories, currentIndex, isMuted, windowHeight } = this.state;
        if(!stories) return null;

        const millisecDefaultInterval = storiesProgressTime * 1000;
        const isMainStory = index === activeEntityIndex;
        const isCurrentStoryHasVideo = stories && stories[currentIndex] && stories[currentIndex].type === "video";
        const slotClass = classNames("slotView", {"muted": isMuted, "videoStory": isCurrentStoryHasVideo});

        return (
            <div className={slotClass} style={{ height: `${windowHeight}px` }}>
                {isMainStory &&
                    <>
                        <div className="closeBtn" onClick={onStoriesClose}></div>
                        <div className="muteBtn" onClick={this.onToggleMute}></div>
                    </>
                }
                <div className="storyItems">
                    {isMainStory ?
                        <div className="mainStory">
                            <ReactInstaStories
                                stories={stories}
                                defaultInterval={millisecDefaultInterval}
                                onNext={this.onNextStory}
                                onPrevious={this.onPrevStory}
                                onAllStoriesEnd={handleNextSlide}
                                currentIndex={currentIndex}
                                onStoryEnd={this.onStoryEnd}
                            />
                            <div className="moreDetails" >
                                <FrontCopyButton popupText={DICT["The link has been copied to the clipboard"]} url={stories[currentIndex] ? stories[currentIndex].link : null} classes="shareBtn" />
                                <div className="mediaArea">
                                    <div className="slotTitle">{promotionName}</div>
                                    <div className="MediaCarousel">
                                        <SiteMedia
                                            data={media}
                                            width={50}
                                            height={50}
                                            videoSettings={null}
                                            itemLinkUrl={link.link.publishedLink}
                                            isImageLinkable={true}
                                            linkTarget={linkTarget}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="storyPlaceholder">
                            <div className="loader"></div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}