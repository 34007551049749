import { PhotoArchiveMainImage } from "./photoArchiveMainImage";
import { ImageDetails } from "../../../../../images/interfaces";
import * as axios from "axios";
import { PhotoArchiveSlotItem } from "./photoArchiveSlotItem";
import { StandartSlotItemMutam } from "../../generalMutamComps/standartSlotItemMutam"
import { ImageSize } from "../../../../../constants/imageSizes"
import { getFrontImageUrl } from "../../../../../front/staticServerRendering/frontImage"

export interface GalleryItems {
    title: string
    publishedLink: string
    path?: string
    author: string
    id: string
    credit: string
    promotionImageDetails?: ImageDetails
    dateUpdatedOnSite: Date
}

interface PhotoArchivePhotoComponentaProps {
    numberOfItems: number
    componentaId: string
    data: any
    extraData: any
    isMobileWeb?: boolean
}

interface PhotoArchivePhotoComponentaState {
    currPage: number
    articles: any[]
    isLoadMore: boolean
    isFirstRender: boolean
    isFirstLoadingBtnClick: boolean
}

const PhotoArchiveDict = {
    LOAD_MORE: "טען עוד",
    NO_RESULTS: "אין תוצאות",
}

export class SitePhotoArchiveComponenta extends React.Component<PhotoArchivePhotoComponentaProps, PhotoArchivePhotoComponentaState>  {
    public static siteScriptName = "SitePhotoArchiveComponenta";
    constructor(props) {
        super(props);
        this.state = {
            currPage: 0,
            articles: this.props.extraData || [],
            isLoadMore: true,
            isFirstRender: true,
            isFirstLoadingBtnClick: true,
        }
    }

    private getArticles = async () => {
        const { componentaId } = this.props;
        const { currPage, articles } = this.state;

        const data = await axios.get(`/iphone/json/api/article_list/${componentaId}/pageNumber/${currPage}`)
            .then((res: any) => {
                const recivedArticles = res.data.data;
                this.setState({
                    articles: currPage > 0 ? [...articles, ...recivedArticles] : recivedArticles
                })
                if (this.state.isFirstRender) {
                    this.setState({
                        isFirstRender: false,
                        currPage: currPage + 1,
                    })
                }
            }).catch(err => console.error("error geting articles data:", err));

        return data;
    }


    componentDidMount() {
        this.getArticles();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.currPage !== prevState.currPage) {
            this.getArticles();
        }
        if (this.state.articles !== prevState.articles) {
            this.setState({ isLoadMore: (this.state.articles.length) / ((this.state.currPage + 1) * this.props.numberOfItems) >= 1 || this.state.isFirstLoadingBtnClick })
        }
    }

    private loadMoreArticles = () => {
        const { currPage } = this.state;
        this.setState({ currPage: currPage + 1, isFirstLoadingBtnClick: false })
    }


    public render() {
        const { data, numberOfItems, isMobileWeb } = this.props;
        const { articles, isLoadMore, currPage, isFirstLoadingBtnClick } = this.state;
        const { tabStyles, tabTitleText } = data;
        const shouldDisplayPageChunck = isLoadMore || isFirstLoadingBtnClick;

        const allItems: GalleryItems[] = articles.length !== 0 ?
            articles.map((item) => {
                return ({
                    title: item.title,
                    publishedLink: item.publishedLink,
                    path: item.promotionImageDetails ? item.promotionImageDetails.url : "",
                    author: item.author,
                    id: item.articleId,
                    credit: "",
                    promotionImageDetails: item.promotionImageDetails,
                    dateUpdatedOnSite: item.dateUpdatedOnSite,
                })
            }) : [];

        const articlesToDisplay = shouldDisplayPageChunck ? allItems.slice(1, 1 + (numberOfItems * currPage)) : allItems.slice(1);

        const emptyItem = {
            title: "",
            publishedLink: "",
            path: "",
            author: "",
            id: "",
            credit: "",
            dateUpdatedOnSite: "",
        }

        return (
            <div className="photoArchiveComponena Auto">
                {isMobileWeb ?
                    <div className="mutamSlotList">
                        {(articles.length > 0) ?
                            allItems.slice(0, 1 + (numberOfItems * currPage)).map((item: GalleryItems) => (
                                <StandartSlotItemMutam
                                    item={{
                                        title: item.title,
                                        link: item.publishedLink,
                                        imageSrc: getFrontImageUrl(item.promotionImageDetails, { imageSize: ImageSize.medium }),
                                        publishedDate: item.dateUpdatedOnSite,
                                        author: item.author
                                    }}
                                    fieldsDisplaySettings={{ isDateVisible: true, isAuthorVisible: true }}
                                />
                            ))
                            :
                            <div className="noResultsMsg">{PhotoArchiveDict.NO_RESULTS}</div>
                        }
                    </div>
                    :
                    <div className="slotsContent">
                        <PhotoArchiveMainImage item={allItems.length !== 0 ? allItems[0] : emptyItem} />

                        <div className="separator" />

                        <div className="photoArchiveTitle" style={tabStyles}>
                            {tabTitleText}
                        </div>

                        <div className="photoArchiveMain">
                            {(articles.length > 0) ?
                                <div className="slotList">
                                    {articlesToDisplay.map((item: GalleryItems) => (
                                        <PhotoArchiveSlotItem key={item.id} item={item} />
                                    ))}
                                </div>
                                :
                                <div className="noResultsMsg">{PhotoArchiveDict.NO_RESULTS}</div>
                            }

                            <div className="loadMore">
                                <button className="loadMoreButton" disabled={!isLoadMore || articles.length <= numberOfItems} onClick={this.loadMoreArticles}>{PhotoArchiveDict.LOAD_MORE}</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

}